a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

a:hover {
  opacity: 0.75;
}

#__next {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

input {
  background-color: inherit;
}

* {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
